import { ErrorPage, NotificationActions } from '@elotech/components';
import { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

export const SILENT_REJECTION = 'SILENT_REJECTION';

type Props = {
  children: ReactNode;
  showNotification: (action: any) => void;
};

type State = {
  hasError: boolean;
  error: any;
  info: any;
};

const DEFAULT_MESSAGE = 'Ops, ocorreu algum problema inesperado.';

class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
      info: null
    };
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.handleRejections);
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.handleRejections);
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error,
      info
    });
  }

  handleRejections = event => {
    // Não mostra mensagem se for cancelamento por parte do usuário
    if (
      event.reason === SILENT_REJECTION ||
      event.reason?.__proto__?.__CANCEL__
    ) {
      return;
    }

    const violations = event.reason?.response?.data?.violations;
    if (violations?.length) {
      return this.props.showNotification({
        level: 'error',
        title: this.getErrorMessage(
          event.reason?.response || event.reason || event
        ),
        message: violations.map(violation => violation.message).join(' / ')
      });
    }

    this.props.showNotification({
      level: 'error',
      message: this.getErrorMessage(
        event.reason?.response || event.reason || event
      )
    });
  };

  getErrorMessage(error: any) {
    if (!error?.data) {
      return error?.message || DEFAULT_MESSAGE;
    }
    const { data } = error;
    if (data && data.message) {
      return data.message;
    } else {
      return typeof data === 'object' ? DEFAULT_MESSAGE : data;
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage message={this.state.error?.toString() || DEFAULT_MESSAGE} />
      );
    }

    return this.props.children;
  }
}

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const ConnectedErrorBoundary = connect(null, mapDispatchToProps)(ErrorBoundary);

export { ConnectedErrorBoundary as default, ErrorBoundary };
