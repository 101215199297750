/* Assinatura */
export const assinatura = 'assinatura_read';
export const assinaturaCreate = 'assinatura_create';
export const assinaturaUpdate = 'assinatura_update';
export const assinaturaDelete = 'assinatura_delete';
export const assinaturaLog = 'assinatura_log';

export const assinaturaConfig = 'assinatura_config';

/* Cadastros */
export const assinante = 'assinante_read';
export const assinanteCreate = 'assinante_create';
export const assinanteUpdate = 'assinante_update';
export const assinanteDelete = 'assinante_delete';
export const assinanteLog = 'assinante_log';

export const assunto = 'assunto_read';
export const assuntoCreate = 'assunto_create';
export const assuntoUpdate = 'assunto_update';
export const assuntoDelete = 'assunto_delete';
export const assuntoLog = 'assunto_log';

export const bairro = 'bairro_read';
export const bairroCreate = 'bairro_create';
export const bairroUpdate = 'bairro_update';
export const bairroDelete = 'bairro_delete';
export const bairroLog = 'bairro_log';

export const caixa = 'caixa_read';
export const caixaCreate = 'caixa_create';
export const caixaUpdate = 'caixa_update';
export const caixaDelete = 'caixa_delete';
export const caixaLog = 'caixa_log';

export const cidade = 'cidade_read';
export const cidadeCreate = 'cidade_create';
export const cidadeUpdate = 'cidade_update';
export const cidadeDelete = 'cidade_delete';
export const cidadeLog = 'cidade_log';

export const coordenadoria = 'coordenadoria_read';
export const coordenadoriaCreate = 'coordenadoria_create';
export const coordenadoriaUpdate = 'coordenadoria_update';
export const coordenadoriaDelete = 'coordenadoria_delete';
export const coordenadoriaLog = 'coordenadoria_log';

export const dashboard = 'dashboard_read';
export const dashboardCreate = 'dashboard_create';
export const dashboardUpdate = 'dashboard_update';
export const dashboardDelete = 'dashboard_delete';
export const dashboardLog = 'dashboard_log';

export const dataIgnorada = 'data_ignorada_read';
export const dataIgnoradaCreate = 'data_ignorada_create';
export const dataIgnoradaUpdate = 'data_ignorada_update';
export const dataIgnoradaDelete = 'data_ignorada_delete';
export const dataIgnoradaLog = 'data_ignorada_log';

export const diretoria = 'diretoria_read';
export const diretoriaCreate = 'diretoria_create';
export const diretoriaUpdate = 'diretoria_update';
export const diretoriaDelete = 'diretoria_delete';
export const diretoriaLog = 'diretoria_log';

export const documentos = 'documentos_read';
export const documentosCreate = 'documentos_create';
export const documentosUpdate = 'documentos_update';
export const documentosDelete = 'documentos_delete';
export const documentosLog = 'documentos_log';

export const entidade = 'entidade_read';
export const entidadeCreate = 'entidade_create';
export const entidadeUpdate = 'entidade_update';
export const entidadeDelete = 'entidade_delete';
export const entidadeLog = 'entidade_log';

export const fluxoAssinatura = 'fluxo_assinatura_read';
export const fluxoAssinaturaCreate = 'fluxo_assinatura_create';
export const fluxoAssinaturaUpdate = 'fluxo_assinatura_update';
export const fluxoAssinaturaDelete = 'fluxo_assinatura_delete';
export const fluxoAssinaturaLog = 'fluxo_assinatura_log';

export const gerencia = 'gerencia_read';
export const gerenciaCreate = 'gerencia_create';
export const gerenciaUpdate = 'gerencia_update';
export const gerenciaDelete = 'gerencia_delete';
export const gerenciaLog = 'gerencia_log';

export const grauSigilo = 'grau_sigilo_read';
export const grauSigiloCreate = 'grau_sigilo_create';
export const grauSigiloUpdate = 'grau_sigilo_update';
export const grauSigiloDelete = 'grau_sigilo_delete';
export const grauSigiloLog = 'grau_sigilo_log';

export const grupoAssinante = 'grupo_assinante_read';
export const grupoAssinanteCreate = 'grupo_assinante_create';
export const grupoAssinanteUpdate = 'grupo_assinante_update';
export const grupoAssinanteDelete = 'grupo_assinante_delete';
export const grupoAssinanteLog = 'grupo_assinante_log';

export const local = 'local_read';
export const localCreate = 'local_create';
export const localUpdate = 'local_update';
export const localDelete = 'local_delete';
export const localLog = 'local_log';

export const logradouro = 'logradouro_read';
export const logradouroCreate = 'logradouro_create';
export const logradouroUpdate = 'logradouro_update';
export const logradouroDelete = 'logradouro_delete';
export const logradouroLog = 'logradouro_log';

export const pessoa = 'pessoa_read';
export const pessoaCreate = 'pessoa_create';
export const pessoaUpdate = 'pessoa_update';
export const pessoaDelete = 'pessoa_delete';
export const pessoaLog = 'pessoa_log';

export const roteiro = 'roteiro_read';
export const roteiroCreate = 'roteiro_create';
export const roteiroUpdate = 'roteiro_update';
export const roteiroDelete = 'roteiro_delete';
export const roteiroLog = 'roteiro_log';

export const secretaria = 'secretaria_read';
export const secretariaCreate = 'secretaria_create';
export const secretariaUpdate = 'secretaria_update';
export const secretariaDelete = 'secretaria_delete';
export const secretariaLog = 'secretaria_log';

export const situacao = 'situacao_read';
export const situacaoCreate = 'situacao_create';
export const situacaoUpdate = 'situacao_update';
export const situacaoDelete = 'situacao_delete';
export const situacaoLog = 'situacao_log';

export const taxaProcesso = 'taxa_processo_read';
export const taxaProcessoCreate = 'taxa_processo_create';
export const taxaProcessoUpdate = 'taxa_processo_update';
export const taxaProcessoDelete = 'taxa_processo_delete';
export const taxaProcessoLog = 'taxa_processo_log';

export const taxaAssuntoProcesso = 'taxa_assunto_processo_read';
export const taxaAssuntoProcessoCreate = 'taxa_assunto_processo_create';
export const taxaAssuntoProcessoUpdate = 'taxa_assunto_processo_update';
export const taxaAssuntoProcessoDelete = 'taxa_assunto_processo_delete';
export const taxaAssuntoProcessoLog = 'taxa_assunto_processo_log';

export const tipo = 'tipo_read';
export const tipoCreate = 'tipo_create';
export const tipoUpdate = 'tipo_update';
export const tipoDelete = 'tipo_delete';
export const tipoLog = 'tipo_log';

export const tipoNotificacao = 'tipo_notificacao_read';
export const tipoNotificacaoCreate = 'tipo_notificacao_create';
export const tipoNotificacaoUpdate = 'tipo_notificacao_update';
export const tipoNotificacaoDelete = 'tipo_notificacao_delete';
export const tipoNotificacaoLog = 'tipo_notificacao_log';

export const tipoParticipante = 'tipo_participante_read';
export const tipoParticipanteCreate = 'tipo_participante_create';
export const tipoParticipanteUpdate = 'tipo_participante_update';
export const tipoParticipanteDelete = 'tipo_participante_delete';
export const tipoParticipanteLog = 'tipo_participante_log';

export const unidade = 'unidade_read';
export const unidadeCreate = 'unidade_create';
export const unidadeUpdate = 'unidade_update';
export const unidadeDelete = 'unidade_delete';
export const unidadeLog = 'unidade_log';

export const unidadeOrcamentaria = 'unidade_orcamentaria_read';
export const unidadeOrcamentariaCreate = 'unidade_orcamentaria_create';
export const unidadeOrcamentariaUpdate = 'unidade_orcamentaria_update';
export const unidadeOrcamentariaDelete = 'unidade_orcamentaria_delete';
export const unidadeOrcamentariaLog = 'unidade_orcamentaria_log';

export const usuario = 'usuario_read';
export const usuarioCreate = 'usuario_create';
export const usuarioUpdate = 'usuario_update';
export const usuarioDelete = 'usuario_delete';
export const usuarioLog = 'usuario_log';

export const tipoAndamento = 'tipo_andamento_read';
export const tipoAndamentoCreate = 'tipo_andamento_create';
export const tipoAndamentoUpdate = 'tipo_andamento_update';
export const tipoAndamentoDelete = 'tipo_andamento_delete';
export const tipoAndamentoLog = 'tipo_andamento_log';

/* Processos */
export const processos = 'abertura_read';
export const processosCreate = 'abertura_create';
export const processosUpdate = 'abertura_update';
export const processosDelete = 'abertura_delete';
export const processosLog = 'abertura_log';

export const abertura = 'abertura_read';
export const aberturaCreate = 'abertura_create';
export const aberturaUpdate = 'abertura_update';
export const aberturaDelete = 'abertura_delete';
export const aberturaLog = 'abertura_log';

export const anexar = 'anexar_read';
export const anexarCreate = 'anexar_create';
export const anexarUpdate = 'anexar_update';
export const anexarDelete = 'anexar_delete';
export const anexarLog = 'anexar_log';

export const referenciar = 'referenciar_read';
export const referenciarCreate = 'referenciar_create';
export const referenciarUpdate = 'referenciar_update';
export const referenciarDelete = 'referenciar_delete';
export const referenciarLog = 'referenciar_log';

export const comprovante = 'comprovante_read';
export const comprovanteCreate = 'comprovante_create';
export const comprovanteUpdate = 'comprovante_update';
export const comprovanteDelete = 'comprovante_delete';
export const comprovanteLog = 'comprovante_log';

export const modeloImpressao = 'modeloimpressao_read';
export const modeloImpressaoCreate = 'modeloimpressao_create';
export const modeloImpressaoUpdate = 'modeloimpressao_update';
export const modeloImpressaoDelete = 'modeloimpressao_delete';
export const modeloImpressaoLog = 'modeloimpressao_log';

export const integracaoTributos = 'integracao_tributos';

export const agrupamentoArquivos = 'agrupamento_arquivos_read';
export const agrupamentoArquivosCreate = 'agrupamento_arquivos_create';
export const agrupamentoArquivosUpdate = 'agrupamento_arquivos_update';
export const agrupamentoArquivosDelete = 'agrupamento_arquivos_delete';
export const agrupamentoArquivosLog = 'agrupamento_arquivos_log';

/* Tramites */
export const receberProcesso = 'receber_processo';
export const encaminharProcesso = 'encaminhar_processo';
export const arquivarProcesso = 'arquivar_processo';
export const reabrirProcesso = 'reabrir_processo';
export const correcaoFluxo = 'correcao_fluxo';

/* Parâmetros */
export const parametros = 'parametros';

/* CubeJs Dashboards */
export const cubeJsCreate = 'cubejs_dashboards_create';
export const cubeJsRead = 'cubejs_dashboards_read';
export const cubeJsUpdate = 'cubejs_dashboards_update';
export const cubeJsDelete = 'cubejs_dashboards_delete';
export const cubeJsLog = 'cubejs_dashboards_log';

/* Relatórios */
export const relatorios = 'relatorios';
export const relatorioGeral = 'relatorio_geral';
export const relatorioComprovanteEncaminhamento =
  'relatorio_comprovante_encaminhamento';
export const relatorioTramite = 'relatorio_tramite';
export const relatorioEtiqueta = 'relatorio_etiqueta';
export const relatorioEstatisticasProcessos = 'relatorio_estatisticas_processo';
export const relatorioControleEmails = 'relatorio_controle_emails';
export const unicoRelatorioDinamico = 'unico_relatorios_dinamicos';

export const atualizacaoPessoa = 'atualizacao_pessoa';

/* Home / dashboards */
export const home = 'home';

/* Consultas */
export const unicoConsultaDinamicaCreate = 'unico_consulta_dinamica_create';
export const unicoConsultaDinamicaRead = 'unico_consulta_dinamica_read';
export const unicoConsultaDinamicaUpdate = 'unico_consulta_dinamica_update';
export const unicoConsultaDinamicaDelete = 'unico_consulta_dinamica_delete';
export const unicoConsultaDinamicaLog = 'unico_consulta_dinamica_log';

/* Único */
export const unicoCadastros = 'unico_cadastros';

/* Modelos de Documentos */

export const modelosDocumentos = 'modelos_documentos_read';
export const modelosDocumentosCreate = 'modelos_documentos_create';
export const modelosDocumentosUpdate = 'modelos_documentos_update';
export const modelosDocumentosDelete = 'modelos_documentos_delete';
export const modelosDocumentosLog = 'modelos_documentos_log';

export const variaveis = 'variaveis_read';
export const variaveisCreate = 'variaveis_create';
export const variaveisUpdate = 'variaveis_update';
export const variaveisDelete = 'variaveis_delete';
export const variaveisLog = 'variaveis_log';

/* Indicadores */
export const indicadores = 'indicadores';
export const indicadorProcessosLocais = 'indicadores_processos_locais';

export const parametrosIntegracaoCreate = 'parametros_integracao_create';
export const parametrosIntegracaoRead = 'parametros_integracao_read';
export const parametrosIntegracaoUpdate = 'parametros_integracao_update';
export const parametrosIntegracaoDelete = 'parametros_integracao_delete';
export const parametrosIntegracaoLog = 'parametros_integracao_log';

export const controleAndamentosList = 'controle_andamentos_list';

/* Interessado */
export const interessadoRead = 'interessado_read';
export const interessadoCreate = 'interessado_create';
export const interessadoUpdate = 'interessado_update';
export const interessadoDelete = 'interessado_delete';
export const interessadoLog = 'interessado_log';
