import {
  ActionButton,
  ActionsGroup,
  Button,
  Col,
  EditableInput,
  FormattedDateTime,
  Loading,
  Panel,
  Row,
  SectionTitle,
  Table,
  useShowNotification
} from '@elotech/components';
import { Informativo, InlineButton, alert } from 'common/components';
import { useAfterToSign, useExpandedIds, useLoading } from 'common/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ArquivoAssinaturaService,
  ArquivoService,
  ParametroService,
  ProcessoArquivoService,
  ProcessoExternoService,
  UsuarioService
} from 'service';
import {
  getConfigSelector,
  getIsLoggedSelector,
  getUserInfoSelector
} from 'state';

import {
  BLOQUEIA_REORDENACAO_ANEXO_TRAMITE_DIFERENTE_DO_ATUAL,
  EXIBIR_ORDEM_CRESCENTE_ANEXO,
  EXIBIR_ORDEM_GERAL_ANEXO,
  PERMITE_RENOMEAR_ANEXOS,
  PERMITE_REORDENAR_ANEXOS_DO_PROCESSO
} from '../../parametros/Parametros';
import AssinaturaActionButton from '../assinatura/AssinaturaActionButton';
import { AssinaturaQuickView } from '../assinatura/AssinaturaQuickView';
import { SolicitarAssinatura } from '../assinatura/SolicitarAssinatura';
import { usePermiteAssinar } from '../assinatura/usePermiteAssinar';
import { ProcessoArquivo } from '../processo/ProcessoArquivo';
import ProcessoArquivosSortable from './ProcessoArquivosSortable';
import { getLegenda } from './ProcessoArquivoUtils';

export const situacaoByAnalise = {
  N: 'Não Analisado',
  A: 'Aprovado',
  R: 'Recusado'
};

export const ProcessoArquivos = ({
  processo,
  onUpdateFile,
  onVisualizarArquivoHtml,
  canRemove = true,
  isSortable = false,
  shouldRename = true,
  canAddArquivos = true,
  doSearch = () => {},
  isConsultaExternaMp = false,
  showAgruparArquivos
}) => {
  const usuarioLogado = useSelector(getUserInfoSelector);
  const [loading, setLoading] = useLoading();
  const [quickAssinatura, setQuickAssinatura] = useState();
  const { expandedIds, handleExpandedIds } = useExpandedIds();
  const [canReorder, setCanReorder] = useState(false);
  const [canReorderSomenteTramiteAtual, setCanReorderSomenteTramiteAtual] =
    useState(false);
  const [canRename, setCanRename] = useState(false);
  const isLogged = useSelector(getIsLoggedSelector);
  const [innerProcesso, setInnerProcesso] = useState(processo);
  const config = useSelector(getConfigSelector);
  const showNotification = useShowNotification();
  const history = useHistory();
  const [isExibeOrdemGeral, setIsExibeOrdemGeral] = useState(true);
  const [ordemCrescente, setOrdemCrescente] = useState(true);
  const [isUsuarioAdministrador, setIsUsuarioAdministrador] = useState(false);
  const [sort, setSort] = useState();
  const handlePermiteAssinar = usePermiteAssinar();

  useEffect(() => {
    setLoading(
      Promise.all([
        ParametroService.findParametro(EXIBIR_ORDEM_GERAL_ANEXO).then(
          ({ data }) => setIsExibeOrdemGeral(data?.valor === 'S')
        ),
        ParametroService.findParametro(EXIBIR_ORDEM_CRESCENTE_ANEXO).then(
          ({ data }) => setOrdemCrescente(data?.valor === 'S')
        )
      ])
    );
  }, []);

  useEffect(() => {
    if (isLogged) {
      setLoading(
        Promise.all([
          ParametroService.findParametro(
            PERMITE_REORDENAR_ANEXOS_DO_PROCESSO
          ).then(({ data }) => setCanReorder(data.valor === 'S')),
          ParametroService.findParametro(PERMITE_RENOMEAR_ANEXOS).then(
            ({ data }) => setCanRename(shouldRename && data.valor === 'S')
          ),
          ParametroService.findParametro(
            BLOQUEIA_REORDENACAO_ANEXO_TRAMITE_DIFERENTE_DO_ATUAL
          ).then(({ data }) =>
            setCanReorderSomenteTramiteAtual(data?.valor === 'S')
          ),
          UsuarioService.isUsuarioAdministrador(usuarioLogado.username).then(
            ({ data }) => setIsUsuarioAdministrador(data)
          )
        ])
      );
    }
  }, [isLogged]);

  const updateOrdemCalculada = useCallback(
    processo => {
      const sortedArquivos = [...(processo.arquivos || [])];

      const quantidade = sortedArquivos.length;

      for (let index = 0; index < sortedArquivos.length; index++) {
        sortedArquivos[index].ordemCalculada = isExibeOrdemGeral
          ? sortedArquivos[index].sequencia
          : ordemCrescente
          ? index + 1
          : quantidade - index;
      }

      return {
        ...processo,
        arquivos: sortedArquivos
      };
    },
    [isExibeOrdemGeral, ordemCrescente]
  );

  useAfterToSign(idsFinalizados => {
    ArquivoAssinaturaService.finalizarAssinaturaDigital(idsFinalizados);
  });

  useEffect(() => {
    setInnerProcesso(updateOrdemCalculada(processo));
  }, [processo, updateOrdemCalculada]);

  const analisarArquivo = (arquivo, tipo, motivo = '') => {
    setLoading(
      ProcessoArquivoService.analisar({
        motivo,
        analise: tipo,
        identificador: arquivo.identificador
      }).then(() => {
        if (tipo === 'R') {
          alert({
            type: 'question',
            title: 'Remover Assinaturas Pendentes',
            text: 'Deseja remover as assinaturas pendentes deste arquivo?',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            onConfirm: () =>
              setLoading(
                ArquivoAssinaturaService.removerPendentes(
                  arquivo.identificador
                ).then(() => onUpdateFile())
              ),
            onCancel: () => onUpdateFile()
          });
        } else {
          onUpdateFile();
        }
      })
    );
  };

  const renderAlertaRejeicaoArquivo = arquivo => {
    alert({
      type: 'warning',
      title: 'Rejeitar',
      input: 'textarea',
      showCancelButton: true,
      cancelButtonColor: '#c7382d',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Rejeitar',
      onConfirm: motivo => analisarArquivo(arquivo, 'R', motivo),
      text: 'Deseja mesmo rejeitar o arquivo?',
      inputPlaceholder: 'Informe o motivo da rejeição',
      inputValidator: value => {
        return new Promise(resolve => {
          if (!value) {
            return resolve('Informe o motivo de estar recusando o arquivo.');
          }

          resolve(false);
        });
      }
    });
  };

  const renderSolicitarAssinaturaComponent = arquivo => (
    <SolicitarAssinatura
      expandedAnexoIds={expandedIds}
      identificador={arquivo.identificador}
      setLoading={setLoading}
      onAnexoClick={handleExpandedIds}
      onUpdateFile={onUpdateFile}
    />
  );

  const onAddFile = file => {
    if (innerProcesso.id) {
      const service = isLogged
        ? ProcessoArquivoService
        : ProcessoExternoService;

      return service
        .salvarArquivos(innerProcesso.id, [file], config.isFileCloud)
        .then(response => handleFiles(response[0].data.arquivos));
    }
  };

  const visualizaArquivoHtml = file => {
    ArquivoService.rawDownload(file).then(({ data }) =>
      onVisualizarArquivoHtml(data, file)
    );
  };

  const onRemoveClick = arquivo => {
    alert({
      title: 'Confirmação',
      type: 'question',
      text: 'Deseja remover o arquivo?',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      onConfirm: async () => {
        await setLoading(onRemove(arquivo));
      }
    });
  };

  const cancelarArquivo = arquivo => {
    alert({
      title: 'Cancelar Arquivo',
      input: 'text',
      type: 'error',
      text: 'Este processo irá anotar o PDF como Cancelado e é IRREVERSÍVEL.',
      inputPlaceholder: 'Digite o motivo do cancelamento',
      showCancelButton: true,
      confirmButtonText: 'Cancelar Documento',
      cancelButtonColor: '#c7382d',
      cancelButtonText: 'Abortar cancelamento',
      onConfirm: motivo =>
        setLoading(
          ProcessoArquivoService.cancelarArquivo(
            arquivo.identificador,
            motivo
          ).then(() => onUpdateFile())
        ),
      inputValidator: value => {
        return new Promise(resolve =>
          resolve(value ? undefined : 'Digite um motivo válido')
        );
      }
    });
  };

  const onSigiloExternoClick = arquivo => {
    arquivo.sigiloExterno = !arquivo.sigiloExterno;
    const novosArquivos = (innerProcesso.arquivos || []).map(novoArquivo => {
      if (novoArquivo.sequencia === arquivo.sequencia) {
        novoArquivo.sigiloExterno = arquivo.sigiloExterno;
      }
      return novoArquivo;
    });

    setLoading(
      ProcessoArquivoService.atualizarArquivos(innerProcesso.id, [
        arquivo
      ]).then(() => handleFiles(novosArquivos))
    );
  };

  const onRemove = file => {
    const arquivos = innerProcesso.arquivos || [];
    const index = arquivos.findIndex(a => {
      if (file.idLocal) {
        return a.idLocal === file.idLocal;
      }

      return a.identificador === file.identificador;
    });

    return setLoading(
      ArquivoService.remove(innerProcesso.id, file.identificador).then(() => {
        handleFiles([
          ...arquivos.slice(0, index),
          ...arquivos.slice(index + 1)
        ]);
      })
    );
  };

  const handleFiles = arquivos => {
    setInnerProcesso(prev => updateOrdemCalculada({ ...prev, arquivos }));
  };

  const validaPermissaoAssinaturaEletronica = (handleClick, param) => {
    if (config.possuiAssinaturaContratada || config.possuiAssinaturaCortesia) {
      handleClick(param);
    } else {
      return showNotification({
        level: 'error',
        message:
          'Rotina indisponível. Entrar em contato com a Elotech no Departamento Comercial'
      });
    }
  };

  const getQtdeArquivosPendentes = arquivo => {
    return arquivo.assinaturas.filter(
      assinatura => !assinatura.dataAssinatura && !assinatura.dataRecusa
    ).length;
  };

  const hasPermissaoIntegracao = arquivo => {
    return (
      !innerProcesso.integracaoAssinatura ||
      arquivo.assinaturas.filter(
        assinatura => assinatura.usuario.id === usuarioLogado.username
      ).length > 0
    );
  };

  const renameFile = arquivo => {
    alert({
      title: 'Renomear anexo',
      input: 'text',
      inputValue: arquivo.nome,
      type: 'info',
      text: 'Renomear nomenclatura do anexo no processo',
      inputPlaceholder: 'Digite o novo nome do anexo',
      showCancelButton: true,
      confirmButtonText: 'Renomear',
      cancelButtonText: 'Cancelar',
      onConfirm: async nome => {
        await setLoading(
          ArquivoService.editarNomeArquivo({
            identificador: arquivo.identificador,
            nome
          }).then(() => onUpdateFile())
        );
      },
      inputValidator: value => {
        return new Promise(resolve => {
          if (value) {
            resolve();
          } else {
            resolve('Digite o nome.');
          }
        });
      }
    });
  };

  const renderActionButtons = arquivo => {
    return (
      <ActionsGroup>
        {canRename && isSortable && isLogged && (
          <ActionButton
            icon="edit"
            label="Renomear anexo"
            onClick={() => renameFile(arquivo)}
          />
        )}
        {arquivo.analise === 'N' &&
          !arquivo.cancelado &&
          !innerProcesso.integracaoAssinatura &&
          isLogged && (
            <>
              <ActionButton
                icon="check"
                label="Aprovar arquivo"
                onClick={() => analisarArquivo(arquivo, 'A')}
              />
              <ActionButton
                icon="times"
                label="Rejeitar arquivo"
                onClick={() => renderAlertaRejeicaoArquivo(arquivo)}
              />
            </>
          )}
        <ActionButton
          icon="download"
          label="Download"
          onClick={() => ArquivoService.download(arquivo)}
        />
        {arquivo?.nome?.toLowerCase().endsWith('.html') &&
          onVisualizarArquivoHtml && (
            <ActionButton
              icon="file-alt"
              label="Carregar modelo"
              onClick={() => visualizaArquivoHtml(arquivo)}
            />
          )}
        <ActionButton
          icon="eye"
          label="Visualizar"
          onClick={() => ArquivoService.preview(arquivo)}
        />
        {canRemove && isLogged && arquivo?.qtdAssinaturasPendentes < 1 && (
          <ActionButton
            icon="trash"
            label="Remover"
            onClick={() => onRemoveClick(arquivo)}
          />
        )}
        {isLogged && (
          <ActionButton
            icon="sign-out-alt"
            label={
              arquivo.sigiloExterno
                ? 'Liberar visualização externa'
                : 'Bloquear visualização externa'
            }
            onClick={() => onSigiloExternoClick(arquivo)}
          />
        )}
        {arquivo?.nome?.toLowerCase().endsWith('.pdf') &&
          !arquivo.cancelado &&
          isLogged && (
            <ActionButton
              icon="ban"
              label="Cancelar arquivo"
              onClick={() => cancelarArquivo(arquivo)}
            />
          )}
        {arquivo?.nome?.toLowerCase().endsWith('.pdf') &&
          isLogged &&
          !arquivo.cancelado &&
          hasPermissaoIntegracao(arquivo) && (
            <>
              <AssinaturaActionButton
                assinatura={arquivo}
                onUpdateFile={onUpdateFile}
                validateAssinar={handlePermiteAssinar}
              />
              {!innerProcesso.integracaoAssinatura && (
                <ActionButton
                  icon="paper-plane"
                  label="Solicitar assinatura"
                  onClick={() =>
                    validaPermissaoAssinaturaEletronica(
                      handleExpandedIds,
                      arquivo.identificador
                    )
                  }
                />
              )}
            </>
          )}
        {arquivo?.nome?.toLowerCase().endsWith('.pdf') &&
          !arquivo.cancelado &&
          isLogged && (
            <ActionButton
              icon="file-signature"
              label="Assinaturas"
              onClick={() => setQuickAssinatura(arquivo)}
            />
          )}
      </ActionsGroup>
    );
  };

  const isItemSortable = item => {
    if (!canReorder || sort) return false;

    const seqTramiteArquivo = item.sequenciaTramitacao;

    const isInUltimoTramite =
      Number(seqTramiteArquivo || 0) === processo?.ultimaSequenciaTramite;

    return canReorderSomenteTramiteAtual ? isInUltimoTramite : true;
  };

  const renderArquivosList = () => {
    if (isSortable) {
      return (
        <>
          {sort && (
            <div className="mb-xs">
              <Button onClick={onClearSort}>Limpar Filtros</Button>
            </div>
          )}
          <Panel isTable scrollHorizontal>
            <ProcessoArquivosSortable
              isProcessoMP={!!processo?.tipo?.tipoProcessoMp}
              handleFiles={handleFiles}
              arquivos={innerProcesso.arquivos}
              renderActionButtons={renderActionButtons}
              renderInnerComponent={renderSolicitarAssinaturaComponent}
              renderHeaderAgruparArquivo={renderHeaderAgruparArquivo}
              onSortChange={setSort}
              sort={sort}
              isItemSortable={isItemSortable}
            />
          </Panel>
        </>
      );
    }

    return (
      <Panel isTable scrollHorizontal>
        <Table
          values={innerProcesso.arquivos}
          keyExtractor={(arquivo, index) => `${arquivo.nome}-${index}`}
          renderInnerComponent={renderSolicitarAssinaturaComponent}
        >
          <Table.Column
            header=""
            value={arquivo =>
              getLegenda(arquivo, processo?.tipo?.tipoProcessoMp)
            }
          />
          <Table.Column
            header="Ordem"
            value={arquivo => arquivo.ordemCalculada}
          />
          <Table.Column
            header="Nome Documento"
            value={arquivo => {
              return (
                <>
                  <EditableInput
                    name="nomeDocumento"
                    readOnly={!isLogged || !canRename}
                    defaultOpened
                    maxLength={150}
                    initialValue={arquivo.nome}
                    onConfirmChange={value => {
                      const arquivoAtualizarNomeDTO = {
                        identificador: arquivo.identificador,
                        nome: value
                      };

                      setLoading(
                        ArquivoService.editarNomeArquivo(
                          arquivoAtualizarNomeDTO
                        )
                      ).then(() => onUpdateFile());
                    }}
                  />
                </>
              );
            }}
          />
          <Table.Column
            header="Usuário"
            value={arquivo =>
              arquivo.usuario === 'USER_EXTERNO'
                ? 'Usuário Externo'
                : `${arquivo.usuario} - ${arquivo.usuarioNome || 'Sem nome'}`
            }
          />
          <Table.Column
            header="Data Envio"
            value={arquivo => <FormattedDateTime value={arquivo.dataCriacao} />}
          />
          <Table.Column
            defaultHidden={!isLogged}
            header="Assinaturas"
            value={arquivo =>
              arquivo.assinaturas.filter(
                assinatura =>
                  !!assinatura.dataAssinatura || !!assinatura.dataRecusa
              ).length
            }
          />
          <Table.Column
            defaultHidden={!isLogged}
            header="Assinaturas Pendentes"
            value={arquivo => getQtdeArquivosPendentes(arquivo)}
          />
          {!innerProcesso.integracaoAssinatura && (
            <Table.Column
              header="Situação Análise"
              value={arquivo =>
                arquivo.cancelado ? (
                  <>
                    {'Cancelado em '}{' '}
                    <FormattedDateTime value={arquivo.dataCancelamento} />
                  </>
                ) : (
                  situacaoByAnalise[arquivo.analise]
                )
              }
            />
          )}
          {!innerProcesso.integracaoAssinatura && (
            <Table.Column
              header="Motivo Recusa"
              value={arquivo =>
                arquivo.cancelado
                  ? arquivo.motivoCancelamento
                  : arquivo.motivoRecusa
              }
            />
          )}
          <Table.Column
            header={renderHeaderAgruparArquivo}
            value={renderActionButtons}
            className="has-btn-actions"
          />
        </Table>
      </Panel>
    );
  };

  const renderLegenda = () => {
    if (
      !innerProcesso?.arquivos?.some(
        arquivo =>
          arquivo.analise === 'R' ||
          arquivo.cancelado ||
          !!arquivo.sigiloExterno ||
          (processo?.tipo?.tipoProcessoMp && !!arquivo.salvoPeloSistema)
      )
    ) {
      return null;
    }

    return (
      <Panel>
        <SectionTitle marginTop="0">Legenda de Identificação</SectionTitle>
        <Informativo type="INFO" classes="negative" withIcon>
          R - Arquivo recusado
        </Informativo>
        <Informativo type="INFO" classes="negative" withIcon>
          C - Arquivo Cancelado
        </Informativo>
        <Informativo type="INFO" classes="warning" withIcon>
          E - Arquivo não demonstrado na consulta externa
        </Informativo>
        <Informativo type="INFO" classes="warning" withIcon>
          P - Possui Assinatura Pendente
        </Informativo>
        <Informativo type="INFO" classes="warning" withIcon>
          R - Possui Assinatura Rejeitada
        </Informativo>
        {processo?.tipo?.tipoProcessoMp && (
          <Informativo type="INFO" classes="success" withIcon>
            T - Template salvo pelo Sistema
          </Informativo>
        )}
      </Panel>
    );
  };

  const renderHeaderAgruparArquivo = () => {
    if (!showAgruparArquivos) {
      return '';
    }

    return (
      <ActionsGroup>
        <ActionButton
          icon="copy"
          label="Agrupar Arquivos"
          onClick={() => showAgruparArquivos?.()}
        />
      </ActionsGroup>
    );
  };

  const onClearSort = () => {
    setSort(null);
  };

  const goToVisualizadorDocumentos = () => {
    history.push(`/processos-novo/view/documentos/${processo.id}`);
  };

  return (
    <>
      <Loading loading={loading} />
      {isConsultaExternaMp ? (
        <>
          <SectionTitle>Visualizar Processo na Íntegra</SectionTitle>
          {renderArquivosList()}
          <SectionTitle>Favor anexar a resposta no campo abaixo</SectionTitle>
          <ProcessoArquivo
            showDocumentoObrigatorio={false}
            showFiles={false}
            processo={innerProcesso}
            onAdd={file => onAddFile(file)}
            canAddArquivos={canAddArquivos}
          />
        </>
      ) : (
        <>
          <ProcessoArquivo
            showDocumentoObrigatorio={false}
            showFiles={false}
            processo={innerProcesso}
            onAdd={file => onAddFile(file)}
            canAddArquivos={canAddArquivos}
          />
          <br />
          {renderArquivosList()}
          <br />
          {isLogged && (
            <Row>
              <Col
                md={12}
                className="mt-xs"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <InlineButton
                  style={{ width: '40%', height: '40px' }}
                  label="Visualizador de documentos"
                  icon="fa fa-eye"
                  onClick={() => {
                    goToVisualizadorDocumentos();
                  }}
                />
              </Col>
            </Row>
          )}
        </>
      )}
      <br />
      {renderLegenda()}
      {quickAssinatura && (
        <AssinaturaQuickView
          arquivo={quickAssinatura}
          isUsuarioAdministrador={isUsuarioAdministrador}
          onChange={() => {
            doSearch();
            onUpdateFile();
          }}
          onClose={() => setQuickAssinatura(undefined)}
        />
      )}
    </>
  );
};
